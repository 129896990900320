import { default as _404smJnjpY0yzMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/404.vue?macro=true";
import { default as _500bFtOfXNzkVMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/500.vue?macro=true";
import { default as forgot_45passwordLqey7heK3tMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/forgot-password.vue?macro=true";
import { default as register_45with_45otpgrtqKdaQrqMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/register-with-otp.vue?macro=true";
import { default as reset_45passwordZBwRpA3p0hMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/reset-password.vue?macro=true";
import { default as sign_45inm96RcrWuH2Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/sign-in.vue?macro=true";
import { default as authc7VGPACzQSMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth.vue?macro=true";
import { default as _91id_932u16D3xcaKMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking-v2/[id].vue?macro=true";
import { default as index1zZIKDQfxWMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking-v2/index.vue?macro=true";
import { default as _91id_93MFHPS1UoRXMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking/[id].vue?macro=true";
import { default as indexgc6HvedlpAMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking/index.vue?macro=true";
import { default as _91clinicId_93FoCji5pWaGMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/clinic/[clinicId].vue?macro=true";
import { default as index8Ic3FyqeTCMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/clinic/index.vue?macro=true";
import { default as dashboard9PVQucdwW1Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/dashboard.vue?macro=true";
import { default as _91id_93KwriN7qzpsMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/doctor/[id].vue?macro=true";
import { default as indexO8JviJHVmjMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/index.vue?macro=true";
import { default as maintenancenHh2Ubl0b1Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/maintenance.vue?macro=true";
import { default as _91id_93onfd3gUPR4Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/[id].vue?macro=true";
import { default as addpiDURFZ6CTMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/add.vue?macro=true";
import { default as indexxheF6UJVfTMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/index.vue?macro=true";
import { default as _91slug_93cXizibpVv0Meta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/tin-tuc/[slug].vue?macro=true";
import { default as indexVK1aKXMTqaMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/tin-tuc/index.vue?macro=true";
import { default as _91id_93LCV5jCTBGvMeta } from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/wv/question/[id].vue?macro=true";
export default [
  {
    name: _404smJnjpY0yzMeta?.name ?? "404",
    path: _404smJnjpY0yzMeta?.path ?? "/404",
    meta: _404smJnjpY0yzMeta || {},
    alias: _404smJnjpY0yzMeta?.alias || [],
    redirect: _404smJnjpY0yzMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500bFtOfXNzkVMeta?.name ?? "500",
    path: _500bFtOfXNzkVMeta?.path ?? "/500",
    meta: _500bFtOfXNzkVMeta || {},
    alias: _500bFtOfXNzkVMeta?.alias || [],
    redirect: _500bFtOfXNzkVMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/500.vue").then(m => m.default || m)
  },
  {
    name: authc7VGPACzQSMeta?.name ?? "auth",
    path: authc7VGPACzQSMeta?.path ?? "/auth",
    meta: authc7VGPACzQSMeta || {},
    alias: authc7VGPACzQSMeta?.alias || [],
    redirect: authc7VGPACzQSMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth.vue").then(m => m.default || m),
    children: [
  {
    name: forgot_45passwordLqey7heK3tMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordLqey7heK3tMeta?.path ?? "forgot-password",
    meta: forgot_45passwordLqey7heK3tMeta || {},
    alias: forgot_45passwordLqey7heK3tMeta?.alias || [],
    redirect: forgot_45passwordLqey7heK3tMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: register_45with_45otpgrtqKdaQrqMeta?.name ?? "auth-register-with-otp",
    path: register_45with_45otpgrtqKdaQrqMeta?.path ?? "register-with-otp",
    meta: register_45with_45otpgrtqKdaQrqMeta || {},
    alias: register_45with_45otpgrtqKdaQrqMeta?.alias || [],
    redirect: register_45with_45otpgrtqKdaQrqMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/register-with-otp.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordZBwRpA3p0hMeta?.name ?? "auth-reset-password",
    path: reset_45passwordZBwRpA3p0hMeta?.path ?? "reset-password",
    meta: reset_45passwordZBwRpA3p0hMeta || {},
    alias: reset_45passwordZBwRpA3p0hMeta?.alias || [],
    redirect: reset_45passwordZBwRpA3p0hMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45inm96RcrWuH2Meta?.name ?? "auth-sign-in",
    path: sign_45inm96RcrWuH2Meta?.path ?? "sign-in",
    meta: sign_45inm96RcrWuH2Meta || {},
    alias: sign_45inm96RcrWuH2Meta?.alias || [],
    redirect: sign_45inm96RcrWuH2Meta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/auth/sign-in.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_932u16D3xcaKMeta?.name ?? "booking-v2-id",
    path: _91id_932u16D3xcaKMeta?.path ?? "/booking-v2/:id()",
    meta: _91id_932u16D3xcaKMeta || {},
    alias: _91id_932u16D3xcaKMeta?.alias || [],
    redirect: _91id_932u16D3xcaKMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking-v2/[id].vue").then(m => m.default || m)
  },
  {
    name: index1zZIKDQfxWMeta?.name ?? "booking-v2",
    path: index1zZIKDQfxWMeta?.path ?? "/booking-v2",
    meta: index1zZIKDQfxWMeta || {},
    alias: index1zZIKDQfxWMeta?.alias || [],
    redirect: index1zZIKDQfxWMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking-v2/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MFHPS1UoRXMeta?.name ?? "booking-id",
    path: _91id_93MFHPS1UoRXMeta?.path ?? "/booking/:id()",
    meta: _91id_93MFHPS1UoRXMeta || {},
    alias: _91id_93MFHPS1UoRXMeta?.alias || [],
    redirect: _91id_93MFHPS1UoRXMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgc6HvedlpAMeta?.name ?? "booking",
    path: indexgc6HvedlpAMeta?.path ?? "/booking",
    meta: indexgc6HvedlpAMeta || {},
    alias: indexgc6HvedlpAMeta?.alias || [],
    redirect: indexgc6HvedlpAMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: _91clinicId_93FoCji5pWaGMeta?.name ?? "clinic-clinicId",
    path: _91clinicId_93FoCji5pWaGMeta?.path ?? "/clinic/:clinicId()",
    meta: _91clinicId_93FoCji5pWaGMeta || {},
    alias: _91clinicId_93FoCji5pWaGMeta?.alias || [],
    redirect: _91clinicId_93FoCji5pWaGMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/clinic/[clinicId].vue").then(m => m.default || m)
  },
  {
    name: index8Ic3FyqeTCMeta?.name ?? "clinic",
    path: index8Ic3FyqeTCMeta?.path ?? "/clinic",
    meta: index8Ic3FyqeTCMeta || {},
    alias: index8Ic3FyqeTCMeta?.alias || [],
    redirect: index8Ic3FyqeTCMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/clinic/index.vue").then(m => m.default || m)
  },
  {
    name: dashboard9PVQucdwW1Meta?.name ?? "dashboard",
    path: dashboard9PVQucdwW1Meta?.path ?? "/dashboard",
    meta: dashboard9PVQucdwW1Meta || {},
    alias: dashboard9PVQucdwW1Meta?.alias || [],
    redirect: dashboard9PVQucdwW1Meta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KwriN7qzpsMeta?.name ?? "doctor-id",
    path: _91id_93KwriN7qzpsMeta?.path ?? "/doctor/:id()",
    meta: _91id_93KwriN7qzpsMeta || {},
    alias: _91id_93KwriN7qzpsMeta?.alias || [],
    redirect: _91id_93KwriN7qzpsMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: indexO8JviJHVmjMeta?.name ?? "index",
    path: indexO8JviJHVmjMeta?.path ?? "/",
    meta: indexO8JviJHVmjMeta || {},
    alias: indexO8JviJHVmjMeta?.alias || [],
    redirect: indexO8JviJHVmjMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenancenHh2Ubl0b1Meta?.name ?? "maintenance",
    path: maintenancenHh2Ubl0b1Meta?.path ?? "/maintenance",
    meta: maintenancenHh2Ubl0b1Meta || {},
    alias: maintenancenHh2Ubl0b1Meta?.alias || [],
    redirect: maintenancenHh2Ubl0b1Meta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91id_93onfd3gUPR4Meta?.name ?? "question-id",
    path: _91id_93onfd3gUPR4Meta?.path ?? "/question/:id()",
    meta: _91id_93onfd3gUPR4Meta || {},
    alias: _91id_93onfd3gUPR4Meta?.alias || [],
    redirect: _91id_93onfd3gUPR4Meta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/[id].vue").then(m => m.default || m)
  },
  {
    name: addpiDURFZ6CTMeta?.name ?? "question-add",
    path: addpiDURFZ6CTMeta?.path ?? "/question/add",
    meta: addpiDURFZ6CTMeta || {},
    alias: addpiDURFZ6CTMeta?.alias || [],
    redirect: addpiDURFZ6CTMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/add.vue").then(m => m.default || m)
  },
  {
    name: indexxheF6UJVfTMeta?.name ?? "question",
    path: indexxheF6UJVfTMeta?.path ?? "/question",
    meta: indexxheF6UJVfTMeta || {},
    alias: indexxheF6UJVfTMeta?.alias || [],
    redirect: indexxheF6UJVfTMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/question/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cXizibpVv0Meta?.name ?? "tin-tuc-slug",
    path: _91slug_93cXizibpVv0Meta?.path ?? "/tin-tuc/:slug()",
    meta: _91slug_93cXizibpVv0Meta || {},
    alias: _91slug_93cXizibpVv0Meta?.alias || [],
    redirect: _91slug_93cXizibpVv0Meta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/tin-tuc/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVK1aKXMTqaMeta?.name ?? "tin-tuc",
    path: indexVK1aKXMTqaMeta?.path ?? "/tin-tuc",
    meta: indexVK1aKXMTqaMeta || {},
    alias: indexVK1aKXMTqaMeta?.alias || [],
    redirect: indexVK1aKXMTqaMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LCV5jCTBGvMeta?.name ?? "wv-question-id",
    path: _91id_93LCV5jCTBGvMeta?.path ?? "/wv/question/:id()",
    meta: _91id_93LCV5jCTBGvMeta || {},
    alias: _91id_93LCV5jCTBGvMeta?.alias || [],
    redirect: _91id_93LCV5jCTBGvMeta?.redirect,
    component: () => import("/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/pages/wv/question/[id].vue").then(m => m.default || m)
  }
]