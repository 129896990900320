import revive_payload_client_4sVQNw7RlN from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vueformPlugin_kEcARWMiqX from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/.nuxt/vueformPlugin.mjs";
import plugin_8SbxDRbG6Y from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import slideovers_LDumGYo2KH from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_i8AMfKeYnY from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import auth_e0FkfVuy48 from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/auth.ts";
import error_t9vrANEheK from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/error.ts";
import repositories_Svc5U6rUsm from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/repositories.ts";
import sentry_h0fMjCG9AB from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/sentry.ts";
import supabase_client_Vu6b6nytQn from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/supabase.client.ts";
import vue_toastificaton_client_YIlzAhW63j from "/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/src/plugins/vue-toastificaton.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vueformPlugin_kEcARWMiqX,
  plugin_8SbxDRbG6Y,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  auth_e0FkfVuy48,
  error_t9vrANEheK,
  repositories_Svc5U6rUsm,
  sentry_h0fMjCG9AB,
  supabase_client_Vu6b6nytQn,
  vue_toastificaton_client_YIlzAhW63j
]