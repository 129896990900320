import { defineNuxtPlugin } from '#imports'

        export default defineNuxtPlugin(async (nuxtApp) => {
          if (import.meta.client) {
            const vueform = (await import('@vueform/vueform')).vueform
            const vueformConfig = (await import('/home/anh/project/outsource/alobacsi/clinic booking/web-user-app/vueform.config.js')).default
            
            nuxtApp.vueApp.use(vueform, vueformConfig)
          }
        })
        